.wallboardHeader {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 15px;
}

.wallboardBackground {
    background-color: #f0f9fa;
    min-height: 100vh;

}

.mainLabel {
    padding: 5px;
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 3vh;
}

.container {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    background-color: #f0f9fa;
    padding: 10px;
    max-width: 100vw !important;
    min-height: 100vh;
    overflow: auto;

}

section {
    padding: 5px;
}

.metricRow {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
}

.cardHeader {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    align-items: flex-end;
}

.cardBody {
    display: flex;

}

.cardBodyCQ {
    display: flex;
    height: 18vh;
}

.metricBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 20px; */
    min-width: 12vw;
}


.chatQueueDiv {
    display: flex;
}

.verticalDivider {
    max-width: 35%;
    /* height: 100%; */
}

.watingTimeCardBody {
    display: flex;
    padding-left: 40px;
}

.slaBar20 {

    max-width: 16vw;
    max-height: 20vh;
    padding-top: 2vh;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 20px; */
    min-width: 12vw;

}

.slaBar30 {
    max-width: 16vw;
    max-height: 20vh;
    padding-left: 50px;
    padding-top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 20px; */
    min-width: 12vw;
}

.slaMainDiv {
    display: block;
    /* padding-left: 30px; */
}

.percentagestyle {
    margin-left: 8vh;
    margin-top: -17vh;
    font-weight: 500;
    font-size: 26px;
}

.circularWordings {
    font-size: 0.8em;
}

.yesterdayAlignment {
    margin-right: 4vh;
    font-size: 2.8vh;

}

.slaSeparator {
    margin-top: 5vh;
    margin-left: -6vh;
    min-width: 200%
}



.noYesterdayMetric {
    font-size: 5.6vh;
    text-align: center;
    margin-top: 4vh;
}

.metricBodyChatQueue {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    min-width: 150px;
    margin-top: 2vh;
}

.currentValueStyle {
    font-size: 5.5vh;
    margin-bottom: 2.5vh;
}

.currentValueStyleMargin {
    font-size: 5.5vh;
    margin-bottom: 2.5vh;
    margin-top: 4vh;
}

.currentValueStyleCQ {
    font-size: 5.5vh;
    margin-top: 1vh;
}

.html {
    background-color: #f0f9fa;
}

.titleSize {

    font-size: 2.5vh;
    font-weight: 500;
    margin-bottom: 1vh;
}

.maindiv {
    min-height: 100vh;
}

.row {
    padding-top: 10px;
}

.col {
    padding-left: 7px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 7px !important;
}

.chatcol {
    padding-left: 7px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 7px !important;
}

.noValue {
    font-size: 32px;
    font-weight: 650;

}

.verticalDividerVisible {
    max-width: 35%;
    visibility: hidden;
}

.textAlignCentre {
    text-align: center;
}
.mrTp10{
    margin-top: 13vh;
}
.mrTp11{
    margin-top:11vh;
}
.mrLft15{
    margin-left: 43vh;
    margin-right:45vh;
}
.dividerLength{
    width:110%;
}